import React, { useState, useEffect, useCallback, } from 'react'
import { useDispatch } from 'react-redux';
import Calendar from 'react-calendar'
import { Formik } from 'formik';
import moment from 'moment'
import './ScheduleForm.css'

import formSchema from './formSchema'

import { scheduleSession } from '../../../../store/reducers/ParticipantReducer';
import { setJoinModalType } from '../../../../store/reducers/SessionReducer'

function ScheduleForm() {
  const dispatch = useDispatch()

  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [invalidDateError, setInvalidDateError] = useState(null)
  const [loading, setLoadingStatus] = useState(false)

  const [timeset] = useState([
    '8:00', '8:15', '8:30', '8:45', '9:00', '9:15', '9:30', '9:45', '10:00', '10:15', '10:30', '10:45',
    '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', 
    '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00',
    '17:15', '17:30', '17:45', '18:00', '18:15', '18:30',
    '18:45', '19:00', '19:15', '19:30', '19:45', '20:00',
    '20:15', '20:30', '20:45', '21:00', '21:15', '21:30',
    '21:45', '22:00', '22:15', '22:30', '22:45', '23:00',
    '23:15', '23:30', '23:45', '24:00',
  ])

  const disableTime = useCallback(
    (time) => {
      const currentTime = selectedDate ? selectedDate.toString().slice(0, 10) + ' ' + time : ''

      return moment(currentTime, 'ddd DD hh:mm:ss').isBefore(moment().add(10, 'minute').toDate())
    },
    [selectedDate],
  );

  useEffect(() => {
    const invalid = disableTime(selectedTime)

    if (invalid) {
      setSelectedTime(null)
    }
  }, [selectedDate, selectedTime, disableTime])

  const handleDate = (type, value) => {
    if (type === 'time') {
      setSelectedTime(value)
    }
    if (type === 'day') {
      setSelectedDate(value)
    }
    setInvalidDateError(null)
  }

  const handleSubmit = (values) => async (validate) => {
    if (!selectedTime || !selectedDate) {
      setInvalidDateError('Kies een datum en een tijdstip!')

      return
    }

    const errors = await validate(values)

    if (Object.values(errors).length > 0) {
      return;
    }

    try {
      const stringifiedDate = selectedDate.toString()
      const date = stringifiedDate.replace('00:00:00', `${selectedTime}:00`)

      const payload = {
        roomId: 1488,
        start_date: +new Date(date),
        email: values.email,
        // phone: values.phone
      }

      setLoadingStatus(true)

      await dispatch(scheduleSession(payload))

      dispatch(setJoinModalType('COPY_MODAL'))
    } catch (error) {
      console.log('error - handleSubmit - ScheduleForm', error.message)
    } finally {
      setLoadingStatus(false)
    }
  }

  return (
    <div className='sf-container'>
      <div className='sf-title'>Plan een sessie</div>
      <div className='sf-time-container'>
        <Calendar
          className='sf-calendar'
          tileClassName='sf-calendar-title'
          defaultView='month'
          view='month'
          value={selectedDate}
          onClickDay={(day) => handleDate('day', day)}
          minDate={new Date()}
        />

        <div className='sf-time-picker'>
          {timeset.map((time, index) => (
            <button
              className={`sf-time-picker-item ${time === selectedTime ? 'active' : ''} ${disableTime(time) ? 'disabled' : ''}`}
              onClick={() => handleDate('time', time)}
              disabled={disableTime(time)}
              key={index}
            >
              {time}
            </button>
          ))}
        </div>
      </div>

      <div className='sf-input-error'>{invalidDateError || ''}</div>

      <Formik
        enableReinitialize
        onSubmit={() => { }}
        initialValues={{
          email: '',
          // phone: '',
        }}
        validateOnChange={false}
        validationSchema={formSchema}
      >
        {({ errors, values, validateForm, handleChange, setFieldError }) => {
          return (
            <>
              <div className='sf-inputs-container'>
                <input
                  id='email'
                  className='sf-input'
                  value={values.email}
                  onChange={handleChange}
                  onFocus={() => setFieldError('email', '')}
                  placeholder='E-mail'
                />

                <div className='sf-input-error'>{errors.email || ''}</div>

                {/* <input
                  id='phone'
                  className='sf-input'
                  value={values.phone}
                  onChange={handleChange}
                  onFocus={() => setFieldError('phone', '')}
                  placeholder='Phone number'
                />

                <div className='sf-input-error'>{errors.phone || ''}</div> */}
              </div>
              <button
                className='sf-submit'
                onClick={() => handleSubmit(values)(validateForm)}
                // disabled={(!selectedDate || !selectedTime)}
              >
                {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    <span>Saving...</span>
                  </>
                ) : 'Plan een sessie'}
              </button>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

export default ScheduleForm
