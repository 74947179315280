import React, { useRef, useCallback, } from 'react'
import { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import './UserDataEntry.css';

import { login, setRoomSettings } from '../../../../store/reducers/ParticipantReducer';
import { setJoinModalType } from '../../../../store/reducers/SessionReducer'
import {StringParam, useQueryParam} from 'use-query-params';

function UserDataEntry() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { gainedToken } = useSelector(state => state.participant);

  const [userData, setUserData] = useState({ password: process.env.NODE_ENV === 'development' ? 'Celebrity@VRT$$Y4' : '', userName: '' })
  const [passwordError, setPasswordError] = useState('')
  const [loading, setLoadingState] = useState(null);

  const gainedTokenRef = useRef(gainedToken);
  const userDataRef = useRef(userData);
  const [video, setVideo] = useQueryParam('video', StringParam);

  useEffect(() => {
    gainedTokenRef.current = gainedToken;
  }, [gainedToken])

  useEffect(() => {
    userDataRef.current = userData;
  }, [userData])

  const joinWithRole = useCallback(async () => {
    const isCelebrity = pathname.includes('/c')

    if (isCelebrity && userData.password !== 'Celebrity@VRT$$Y4') {
      setPasswordError('Incorrect password')
      return;
    }

    try {
      setLoadingState(true);

      const payload = {
        userName: userDataRef?.current?.userName,
        isCelebrity,
        isViewer: false,
        token: gainedTokenRef.current,
        video: video,
      }

      if (!isCelebrity) {
        await dispatch(login(payload));
      } else {
        dispatch(setRoomSettings(payload))
      }

      // if (isCelebrity) {
      //     dispatch(getSessionList())
      // }

      dispatch(setJoinModalType(null))
    } catch (error) {
      console.log('error - joinWithRole', error.message)
    } finally {
      setLoadingState(false);
    }
  }, [dispatch, pathname, userData.password])

  useEffect(() => {
    const listener = document.addEventListener('keypress', (e) => {

      if (e.keyCode === 13) {
        e.preventDefault()

        if (!userDataRef?.current?.userName) {
          return
        }

        joinWithRole()
      }
    })

    return () => {
      document.removeEventListener('keypress', listener)
    }
  }, [joinWithRole, dispatch, pathname, userData.password])

  function handleChange(e) {
    setUserData({ ...userData, [e.target.name]: e.target.value })
  }

  return (
    <div className='uda-container'>
      <form>
        <div className="uda-input-container">
          <label className={'uda-label'}>Display Name</label>
          <input
            className="uda-input"
            name="userName"
            autoFocus={true}
            value={userData.userName}
            onChange={handleChange}
            id="userName"
          />
        </div>

        {pathname.includes('/c') && (
          <div className="uda-input-container">
            <input
              className="uda-input"
              name="password"
              placeholder='Enter password'
              type='password'
              value={userData.password}
              onChange={handleChange}
              onFocus={() => setPasswordError('')}
              id="password"
            />

            <div className='uda-input-error'>{passwordError || ''}</div>
          </div>
        )}

        <div className='uda-buttons-container'>
          <button
            type="button"
            className="uda-button participant"
            onClick={joinWithRole}
            tabIndex={0}
            disabled={loading || !userData.userName || (pathname.includes('/c') && !userData.password)}
          >
            {loading === 'participant' ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                <span>Connecting...</span>
              </>
            ) : (
                <span>
                  Start your room
                </span>
              )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserDataEntry
