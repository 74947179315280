import React from 'react'
import { useSelector } from 'react-redux';
import './ParticipantsList.css'

import Video from '../../../Video/Video';

function ParticipantsList(props) {
  const { isCelebrity } = useSelector(state => state.participant);
  const { participants } = useSelector(state => state.session);

  if (!participants || participants.length > 0) {
    return participants.filter(p => !p.isCelebrity).map((participant, index) => (
      <div
        className='s-participant-video-block'
        key={index}
      >
        <Video participant={participant} />
      </div>
    ))
  }

  if (!isCelebrity) {
    return (
      <>
        <div className='s-participant-video-block placeholder'>
          <div className='s-participant-placeholder' />
        </div>
        <div className='s-participant-video-block placeholder'>
          <div className='s-participant-placeholder' />
        </div>
      </>
    )
  }

  return null
}

export default ParticipantsList
