import React, { useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import WT from '@sscale/wtsdk';
import './CelebrityTable.css'

import { setStreamLink } from '../../../../store/reducers/ParticipantReducer'
import {selectGroupName, startSynchronize, stopSync} from "../../../../services/syncService";

function CelebrityTable(props) {
  const { visible, data = [], makeCelebrityLocal } = props

  const dispatch = useDispatch()

  let { userName } = useSelector(state => state.participant);

  const [connectedRoomToken, setConnectedRoomToken] = useState(null)

  const processCelebrityAction = async ({ room, stream, token, action }) => {
    if (action === 'connect') {
      WT.Session.disconnect()
      setConnectedRoomToken(token)
      dispatch(setStreamLink(stream))
      const last = room.room_link.lastIndexOf('/')

      selectGroupName(room.room_link.slice(last + 1), userName);
      WT.Session.connect(token, userName, {}, { isCelebrity: true })
      startSynchronize()
    } else {
      WT.Session.disconnect()
      dispatch(setStreamLink('https://live-cf-vrt.akamaized.net/groupb/live/cbe5b53e-2892-4124-9fba-a9827925624d/live.isml/.m3u8'))
      setConnectedRoomToken(null)
      makeCelebrityLocal()
      stopSync();
    }
  }

  if (!visible) {
    return null
  }

  return (
    <table className='s-session-table'>
      <tr>
        <th>№ Number</th>
        <th>Session ID</th>
        <th>Room name</th>
        <th>Available</th>
        <th>Connect with fans</th>
      </tr>
      {data.map((room, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{room.id}</td>
          <td>{room.room_name}</td>
          <td>{room.available_for_celebrity ? 'Yes' : 'No'}</td>
          <td>
            <button
              className={`s-session-table_connect ${connectedRoomToken === room.room_token ? 'connected' : ''}`}
              onClick={() => processCelebrityAction({room, stream: room?.stream, token: room.room_token, action: !connectedRoomToken ? 'connect' : 'disconnect' })}
            >
              {connectedRoomToken === room.room_token ? (
                'disconnect'
              ) : (
                  'connect'
                )}
            </button>
          </td>
        </tr>
      ))}
    </table>
  )
}

export default CelebrityTable
