import React, { useEffect, useMemo, useState } from 'react'
import WT from '@sscale/wtsdk'
import './Video.css'

function Video({ participant }) {
  const [videoState, setVideoState] = useState('')
  const [audioState, setAudioState] = useState('')

  useEffect(() => {
    WT.ParticipantListeners.onParticipantMediaStreamChanged(({ participantId, mediaType, mediaState }) => {
      if (participantId === participant.participantId) {
        switch (mediaType) {
          case 'VIDEO':
            return setVideoState(mediaState)
          case 'AUDIO':
            return setAudioState(mediaState)
          default:
            return setAudioState('DISABLED')
        }
      }
    })

    WT.ParticipantListeners.onParticipantSpeaking((participantId) => {
      if (participantId === participant.participantId) {
        const elem = document.getElementById(`video-container-${participant.participantId}`);

        if (elem) {
          elem.classList.add('active-speaker')
        }
      }
    })

    WT.ParticipantListeners.onParticipantStopSpeaking((participantId) => {
      if (participantId === participant.participantId) {
        const elem = document.getElementById(`video-container-${participant.participantId}`);

        if (elem) {
          elem.classList.remove('active-speaker');
        }
      }
    })
  }, [participant.participantId])

  const memoizedVideo = useMemo(() => {
    return (
      <video
        className='s-participant-video'
        muted={!!participant.local}
        id={'participant-' + participant.participantId}
        playsInline
        autoPlay
        disablePictureInPicture
        ref={(r) => {
          if (r) {
            r.srcObject = participant.stream
          }
        }}
      />
    )
  }, [participant.participantId, participant.local, participant.stream])

  if (!participant.stream) {
    return null;
  }

  function toggleCamera() {
    if (participant.local) {
      if (WT.Participant.isVideoEnabled()) {
        WT.Participant.disableVideo()
      } else {
        WT.Participant.enableVideo();
      }
    }
  }

  function toggleMic() {
    if (participant.local) {
      if (WT.Participant.isAudioEnabled()) {
        WT.Participant.disableAudio();
      } else {
        WT.Participant.enableAudio();
      }
    }
  }


  return (<div id={`video-container-${participant.participantId}`} className='video-container' key={participant.participantId}>
    {memoizedVideo}
    <div className='control-video-block'>
      <div className='participant-camera' onClick={toggleCamera}>
        <div className={`video-icon ${videoState}`} alt='camera' />
      </div>
      <div className='participant-mic' onClick={toggleMic}>
        <div className={`mic-icon ${audioState} ${participant.local === undefined ? 'DISABLED' : ''}`} alt='mic' />
      </div>

      <div className='participant-name'>
        <div>{participant.participantName}</div>
      </div>
    </div>
  </div>)
}

export default Video
