import React from 'react'
import {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import WT from '@sscale/wtsdk';
import moment from 'moment'
import './Session.css'

import {vrtApiRequest} from '../../httpClient';
import {initializeSyncSdk} from '../../services/syncService'

import {resetStore as resetParticipantStore, setGainedToken} from '../../store/reducers/ParticipantReducer';
import {
    setSessionError, setJoinModalState,
    setRoomProcessingModalState, setRoomCheckerError, setTimeBeforeMeeting,
    setJoinModalType, setParticipants, setStream
} from '../../store/reducers/SessionReducer';

import Video from '../Video/Video';
import JoinModal from '../JoinModal/JoinModal'
import RoomCheckerModal from '../RoomCheckerModal/RoomCheckerModal'
import CelebrityTable from './components/CelebrityTable/CelebrityTable'
import FooterButtons from './components/FooterButtons/FooterButtons'
import Player from '../Player/Player'
import SessionError from './components/SessionError/SessionError';
import ParticipantsList from './components/ParticipantsList/ParticipantsList'
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import fifaeLogo from '../../assets/fifa-e-logo.svg'

function Session() {
    const {roomId = ''} = useParams();

    const dispatch = useDispatch()

    const {isCelebrity, token, userName, room_link} = useSelector(state => state.participant);
    const {participants} = useSelector(state => state.session);

    const [isStreamCreated, setStreamCreateState] = useState(false)
    const [roomHasCelebrity, setRoomCelebrityPresence] = useState(false);
    const [roomsList, setRoomsList] = useState([])
    const [copied, setCopiedState] = useState(false)

    const participantsRef = useRef(participants);
    const roomHasCelebrityRef = useRef(roomHasCelebrity);
    const isCelebrityRef = useRef(isCelebrity);
    const intervalIdRef = useRef(0);

    useEffect(() => {
        isCelebrityRef.current = isCelebrity;
    }, [isCelebrity])

    useEffect(() => {
        if (isCelebrityRef.current) {
            setStreamCreateState(true)
            intervalIdRef.current = setInterval(() => getRoomsList(), 3000)
        }

        return () => {
            clearInterval(intervalIdRef.current)
        }
    }, [isCelebrityRef.current])

    useEffect(() => {
        initializeSyncSdk()
        processRoom()
    }, [roomId])

    useEffect(() => {
        if (isCelebrity !== null && userName) {
            if (isCelebrity) {
                if (roomHasCelebrity) {
                    return
                }

                makeCelebrityLocal()

            } else {
                if (token && userName) {
                    WT.Session.connect(token, userName, {}, {isCelebrity: isCelebrity})
                }
            }
        }
    }, [token, userName, isCelebrity]);

    useEffect(() => {
        participantsRef.current = participants;
    }, [participants]);

    useEffect(() => {
        roomHasCelebrityRef.current = roomHasCelebrity;
    }, [roomHasCelebrity]);

    useEffect(() => {
        WT.ErrorsListeners.onSessionError((error) => {
            if (error.code === 206) {
                dispatch(setSessionError('Unable to join the room. Exceeded limit of streaming participants'))
                dispatch(resetParticipantStore())
                dispatch(setParticipants([]))
            } else {
                dispatch(setSessionError('Unable to connect to server'))
                dispatch(resetParticipantStore())
                dispatch(setParticipants([]))
            }
        });

        let participants = [];

        WT.SessionListeners.onConnected((params) => {
            if (!params.length) {
                dispatch(setJoinModalState(true))
            }

            WT.SessionListeners.onStreamCreated((params) => {
                setStreamCreateState(true)

                if (params.local) {
                    WT.Participant.setMediaQuality({videoHeight: 320, videoWidth: 240, frameRate: 15});
                }

                const alreadyExists = participants.find(p => p.participantId === params.participantId);

                if (alreadyExists) {
                    const video = document.getElementById('participant-' + params.participantId);
                    video.src = params.stream;
                    // dispatch(setParticipants([...participants.filter(p => p.participantId !== params.participantId), { ...params }]))
                } else if (params.isCelebrity) {
                    setRoomCelebrityPresence(params)
                } else {
                    participants.push(params)
                    dispatch(setParticipants([...participants]))
                }
            });

            WT.ParticipantListeners.onParticipantLeft(({participantId}) => {
                if (roomHasCelebrityRef?.current && roomHasCelebrityRef?.current?.participantId === participantId) {
                    setRoomCelebrityPresence(null)
                } else {
                    participants = [...participants.filter(p => p.participantId !== participantId)];
                    dispatch(setParticipants(participants.filter(p => p.participantId !== participantId)))
                }
            })
        })
    }, []);

    const makeCelebrityLocal = () => {
        dispatch(setParticipants([]))
        const constraints = {
            'video': true,
            'audio': false
        }
        navigator.mediaDevices.getUserMedia(constraints).then(device => (
            setRoomCelebrityPresence({stream: device, participantId: '1'})
        ))
    }

    const stopLocalStream = () => {
        const tracks = roomHasCelebrityRef?.current?.stream?.getTracks()

        if (tracks && tracks.length > 0) {
            tracks[0].stop()
        }
    }

    useEffect(() => {
        return () => {
            stopLocalStream()
        }
    }, [])

    const processRoom = async () => {
        if (roomId && roomId !== 'c') {
            dispatch(setRoomProcessingModalState(true))

            try {
                const res = await vrtApiRequest.get(`/room/${roomId}`);

                dispatch(setJoinModalType('USER_DATA_ENTRY'))
                dispatch(setStream(res?.data?.stream))

                if (res.data.token) {
                    dispatch(setGainedToken(res?.data))

                    dispatch(setRoomProcessingModalState(false))
                } else {
                    dispatch(setTimeBeforeMeeting(moment.duration(res?.data?.toStart, 'minutes').humanize()))
                }

            } catch (error) {
                if (error.message.includes('400')) {
                    dispatch(setRoomCheckerError('Room does not exist. Please try to use another link.'))
                    return
                }
                dispatch(setRoomCheckerError('Unexpected error'))
            }
        }
    }

    const getRoomsList = async () => {

        const res = await vrtApiRequest.get('/room/get-available-rooms-for-celebrity');

        setRoomsList(res?.data || [])
    }

    const copyLink = () => {
        const el = document.createElement('textarea');
        el.value = room_link;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        setCopiedState(true)

        setTimeout(() => {
            setCopiedState(false)
        }, 1400)
    };

    function leaveCall() {
        WT.Session.disconnect();

        stopLocalStream()

        dispatch(setJoinModalState(false))
        setRoomCelebrityPresence(null)
        dispatch(setParticipants([]))
        dispatch(resetParticipantStore())

        dispatch(setJoinModalType('BUTTONS'))
    }

    return (
        <div className={'app-container'}>
            <div className={'session-wrapper-container'}>
                <div className={'session-wrapper'}>
                    <div className='session-container'>

                        {roomHasCelebrity && (
                            <div className='s-participants-container right_margin'>
                                <div className='s-participants-empty-space'/>

                                <div className='s-participant-video-block-wrapper'>
                                    <div className='s-participant-video-block celebrity'>
                                        <Video participant={roomHasCelebrity}/>
                                    </div>
                                </div>

                                {isCelebrity && (
                                    <FooterButtons leaveCall={leaveCall}/>
                                )}
                            </div>
                        )}

                        <div className='s-activity-container'>
                            <div className='s-logo-space'>
                                <img src={fifaeLogo} alt={'FIFAe'}/>
                            </div>

                            <div
                                className={`s-player-container ${!token ? 'placeholder' : ''} ${isCelebrity ? 'celebrity' : ''}`}>
                                {isStreamCreated && (token || isCelebrity) ? (
                                    <Player isCelebrity={isCelebrity}/>
                                ) : (
                                    <div className='s-player-button-placeholder'/>
                                )}
                            </div>

                            <div className={`s-session-footer ${isCelebrity ? 'celebrity' : ''}`}>
                                <CelebrityTable
                                    visible={isCelebrity}
                                    data={roomsList}
                                    makeCelebrityLocal={makeCelebrityLocal}
                                />

                                {!isCelebrity && (
                                    <FooterButtons leaveCall={leaveCall}/>
                                )}
                            </div>
                        </div>

                        <div className={'s-right-container'}>
                            <div className='s-back-space'>
                                <a href={'https://www.fifa.gg'} title={'FIFA.gg'} className={'s-back-button'}
                                   target={'_blank'}>Back to FIFA.GG</a>
                            </div>
                            <div className='s-participants-container left_marging'>
                                <ParticipantsList/>
                                <button className={'copy-link-button'} onClick={copyLink}>
                                    <FontAwesomeIcon className={'paperclip-icon'} icon={faPaperclip}/>
                                    <span className={'copy-link-button-text'}>{copied ? 'Link copied' : 'Copy Link To Invite Friends'}</span>
                                    <span className={'copy-link-button-text-tablet'}>{copied ? 'Copied' : 'Copy Link'}</span>
                                </button>
                            </div>
                        </div>


                        <JoinModal/>

                        <RoomCheckerModal/>

                        <SessionError/>

                    </div>
                </div>
            </div>
            <div className={'copy-link-container'}>
                <div className={'copy-link-wrapper'}>
                    <div className={'copy-link-part'}>
                        <h1 className={'copy-link-header'}>Want to double the fun? Invite more people!</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Session;
