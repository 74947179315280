import React, { useState, } from 'react'
import { useSelector } from 'react-redux';
import './FooterButtons.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function FooterButtons(props) {
  const { leaveCall } = props
  let { isCelebrity, room_link } = useSelector(state => state.participant);

  const [copied, setCopiedState] = useState(false)

  const copyLink = () => {
    const el = document.createElement('textarea');
    el.value = room_link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setCopiedState(true)

    setTimeout(() => {
      setCopiedState(false)
    }, 1400)
  };

  return (
    <div className={`sfb-buttons-container `}>
      <div className='sfb-footer-buttons'>
        <button
          className='sfb-button'
          onClick={leaveCall}
        >
          <FontAwesomeIcon className={'arrow-icon'} icon={faArrowLeft} />
          <span>Leave session</span>


        </button>
      </div>
    </div>
  )
}

export default FooterButtons
