import { vrtApiRequest } from '../../httpClient';

const SET_ROOM_SETTINGS = 'SET_ROOM_SETTINGS';
const RESET_STORE = 'RESET_STORE';
const SET_SCHEDULED_SESSION_LINK = 'SET_SCHEDULED_SESSION_LINK';
const SET_GAINED_TOKEN = 'SET_GAINED_TOKEN'
const SET_STREAM_URL = 'SET_STREAM_URL'

const initialState = {
  token: '',
  userName: '',
  sessionId: '',
  isCelebrity: null,
  scheduledSessionLink: '',
  gainedToken: '',
  room_link: '',
  stream: ''
};

export function setRoomSettings(payload) {
  return {
    type: SET_ROOM_SETTINGS,
    payload,
  }
}

export function setGainedToken(payload) {
  return {
    type: SET_GAINED_TOKEN,
    payload,
  }
}

export function login(payload) {
  return async (dispatch, getState) => {
    try {
      const {stream} = getState().participant;

      if (!payload?.token) {
        const response = await vrtApiRequest.post('/room/create', {
          stream: payload?.video
        });

        dispatch(setRoomSettings({
          ...payload,
          ...response.data,
        }))
      } else {
        dispatch(setRoomSettings(payload))
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  }
}

export function scheduleSession(payload) {
  return async (dispatch, getState) => {
    try {
      const {stream} = getState().participant;
      const response = await vrtApiRequest.post('/schedule/create', { ...payload, stream });

      dispatch(setScheduledSessionLink(response?.data?.link || ''))
    } catch (e) {
      console.error('Error: ', e);
    }
  }
}

export function setScheduledSessionLink(payload) {
  return {
    type: SET_SCHEDULED_SESSION_LINK,
    payload,
  }
}

export function setStreamLink(payload) {
  return {
    type: SET_STREAM_URL,
    payload
  }
}

export function resetStore() {
  return {
    type: RESET_STORE,
    payload: {
      token: '',
      username: '',
      sessionId: '',
      isCelebrity: false,
      stream: ''
    }
  }
}

const ACTION_HANDLERS = {
  [SET_ROOM_SETTINGS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    }
  },
  [SET_SCHEDULED_SESSION_LINK]: (state, action) => {
    return { ...state, scheduledSessionLink: action.payload }
  },
  [SET_GAINED_TOKEN]: (state, action) => {
    return { ...state, gainedToken: action.payload.token,  room_link: action.payload.room_link, stream: action.payload.stream}
  },
  [RESET_STORE]: (state, action) => {
    return { ...state, ...action.payload }
  },
  [SET_STREAM_URL]: (state, action) => {
    return { ...state, stream: action.payload }
  },
};

export default function ParticipantReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
