import React, { useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from "react-router-dom";
import './RoomCheckerModal.css'

import { vrtApiRequest } from '../../httpClient';

import { setRoomProcessingModalState } from '../../store/reducers/SessionReducer'

function RoomCheckerModal() {
  const history = useHistory();
  const { roomId = null } = useParams();
  const { pathname } = useLocation();

  const dispatch = useDispatch()

  const { roomProcessingModalOpened, roomCheckerError, timeBeforeMeeting } = useSelector(state => state.session)

  const [email, setEmail] = useState('')
  const [incorrectEmail, setIncorrectEmail] = useState('')

  const handleChange = (e) => {
    const value = e.target.value

    setEmail(value)
  }

  const handleSubmit = async () => {
    if (!email) {
      return setIncorrectEmail('E-mail is required')
    }

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    if (!emailRegex.test(email)) {
      return setIncorrectEmail('Invalid email format')
    }

    await vrtApiRequest.post('/schedule/attach-participant', { email, room: roomId })

    closeModal()
  }

  const closeModal = () => {
    dispatch(setRoomProcessingModalState(false))

    if (pathname.includes('/c')) {
      history.push('/c')
    } else {
      history.push('/')
    }
  }

  if (!roomProcessingModalOpened) {
    return null;
  }

  return (
    <div className='mc-wrapper'>
      <div className='mc-container'>
        <div className='mc-close' onClick={closeModal} />

        {roomCheckerError || (
            timeBeforeMeeting ? (
              <div className='mc-content'>
                <div className='mc-title'>
                  This meeting will start {`in ${timeBeforeMeeting}` || 'soon'}
                </div>

                <div className='mc-caption'>
                  We will remind you when the meeting is started
                </div>

                <input
                  id='email'
                  className='mc-input'
                  value={email}
                  onChange={handleChange}
                  onFocus={() => setIncorrectEmail('')}
                  placeholder='E-mail'
                />

                <div className='mc-input-error'>{incorrectEmail || ''}</div>

                <button
                  className='mc-submit'
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            ) : (
                <div className='mc-title'>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Setting up...
                </div>
              )
          )}
      </div>
    </div>
  )
}

export default RoomCheckerModal;
