import React, { useState, } from 'react'
import { useSelector } from 'react-redux';
import './CopyModal.css'

function CopyModal() {
  const { scheduledSessionLink } = useSelector(state => state.participant);

  const [copied, setCopiedState] = useState(null)

  const copyLink = () => {
    const el = document.createElement('textarea');
    el.value = scheduledSessionLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    setCopiedState(true)

    setTimeout(() => {
      setCopiedState(false)
    }, 1400)
  };

  return (
    <div className='cm-container'>
      <div className='cm-title'>
        Deel deze link met de andere deelnemers.
      </div>

      <div
        className='cm-link'
        onClick={copyLink}
      >
        {scheduledSessionLink}
      </div>

      <button 
        className={`cm-button ${copied ? 'active' : ''}`}
        onClick={copyLink}
      >
        {copied ? (
          'Gekopieerd!'
        ) : (
          'Kopieer de link'
        )}
      </button>
    </div>
  )
}

export default CopyModal
