import { SyncSDK, HtmlVideoPlayer, YoutubePlayer, Callbacks } from '@sscale/syncsdk';


let syncInstance;

export const initializeSyncSdk = () => {
  syncInstance = new SyncSDK(process.env.REACT_APP_SYNC_TOKEN)
}

export const selectGroupName = (groupId, clientName) => {
  if(groupId && clientName)
    syncInstance.createGroup(groupId, clientName);
}
export const stopSync = () => {
  syncInstance.stopSynchronize();
}

export const setClientToSdk = (player) => {
  const playerInstance = document.getElementById(player.id);
  const playerObj = new HtmlVideoPlayer({ id: player.id, videoUrl: player.videoUrl })
  playerObj.setPlayerInstance(playerInstance)
  syncInstance.addPlayer(playerObj, player.playerType)
}


export const setYoutubeToSdk = (player, instance) => {
  const playerObj = new YoutubePlayer({ id:player.id, videoUrl: player.videoUrl })
  playerObj.setPlayerInstance(instance)
  syncInstance.addPlayer(playerObj, player.playerType)
}

export const startSynchronize = () => {
  syncInstance.startSynchronize()
}

export const attachEventListener = (callback)  => {
  syncInstance?.attachListener(callback, Callbacks.receive_command)
}

export const sendEvent = (message) => {
  syncInstance?.sendEventToGroup(message)
}
