const RESET_SESSION_STORE = 'RESET_SESSION_STORE';
const SET_SESSION_ERROR = 'SET_SESSION_ERROR';
const SET_JOIN_MODAL_STATE = 'SET_JOIN_MODAL_STATE';
const SET_SURVEY_MODAL_STATE = 'SET_SURVEY_MODAL_STATE';
const SET_ROOM_PROCESSING_MODAL_OPENED = 'SET_ROOM_PROCESSING_MODAL_OPENED';
const SET_ROOM_CHECKER_ERROR = 'SET_ROOM_CHECKER_ERROR';
const SET_TIME_BEFORE = 'SET_TIME_BEFORE';
const SET_JOIN_MODAL_TYPE = 'SET_JOIN_MODAL_TYPE';
const SET_STREAM = 'SET_STREAM';
const SET_PARTICIPANTS = 'SET_PARTICIPANTS';

const initialState = {
  sessionError: null,
  joinModalOpened: false,
  joinModalType: 'BUTTONS',
  roomProcessingModalOpened: null,
  roomCheckerError: null,
  timeBeforeMeeting: null,
  stream: null,
  participants: [],
};

export function resetStore() {
  return {
    type: RESET_SESSION_STORE,
    payload: initialState,
  }
}

export function setSessionError(payload) {
  return {
    type: SET_SESSION_ERROR,
    payload,
  }
}

export function setJoinModalState(payload) {
  return {
    type: SET_JOIN_MODAL_STATE,
    payload,
  }
}

export function setRoomProcessingModalState(payload) {
  return {
    type: SET_ROOM_PROCESSING_MODAL_OPENED,
    payload,
  }
}

export function setRoomCheckerError(payload) {
  return {
    type: SET_ROOM_CHECKER_ERROR,
    payload,
  }
}

export function setTimeBeforeMeeting(payload) {
  return {
    type: SET_TIME_BEFORE,
    payload,
  }
}

export function setJoinModalType(payload) {
  return {
    type: SET_JOIN_MODAL_TYPE,
    payload,
  }
}

export function setStream(payload) {
  return {
    type: SET_STREAM,
    payload,
  }
}

export function setParticipants(payload) {
  return {
    type: SET_PARTICIPANTS,
    payload,
  }
}

const ACTION_HANDLERS = {
  [RESET_SESSION_STORE]: (state, action) => {
    return action.payload
  },
  [SET_SESSION_ERROR]: (state, action) => {
    return { ...state, sessionError: action.payload }
  },
  [SET_JOIN_MODAL_STATE]: (state, action) => {
    return { ...state, joinModalOpened: action.payload }
  },
  [SET_ROOM_PROCESSING_MODAL_OPENED]: (state, action) => {
    return { ...state, roomProcessingModalOpened: action.payload }
  },
  [SET_ROOM_CHECKER_ERROR]: (state, action) => {
    return { ...state, roomCheckerError: action.payload }
  },
  [SET_TIME_BEFORE]: (state, action) => {
    return { ...state, timeBeforeMeeting: action.payload }
  },
  [SET_JOIN_MODAL_TYPE]: (state, action) => {
    return { ...state, joinModalType: action.payload }
  },
  [SET_STREAM]: (state, action) => {
    return { ...state, stream: action.payload }
  },
  [SET_PARTICIPANTS]: (state, action) => {
    return { ...state, participants: action.payload }
  },
};

export default function SessionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
