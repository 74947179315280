import React, { useEffect, useRef, useState } from 'react';
//import YTLoader from 'youtube-iframe';
import YouTube from 'react-youtube';
import { PlayerType } from '@sscale/syncsdk';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import './Player.css';

import {
  setClientToSdk, startSynchronize, selectGroupName, setYoutubeToSdk,
  sendEvent, attachEventListener
} from "../../services/syncService";
import { useSelector } from 'react-redux';

const Player = ({isCelebrity}) => {
  const [videoFormat, setVideoFormat] = useState(null);
  const { stream, userName = '', room_link } = useSelector(state => state.participant);
  let [video, setVideo] = useQueryParam('video', StringParam);

  if (stream) {
    video = stream;
  }
  useEffect(() => {
    const videoURL = video;
    if(videoURL) {
      const last = room_link.lastIndexOf('/')
      console.log('room_link.slice(last + 1) ', room_link.slice(last + 1))
      selectGroupName(room_link.slice(last + 1), userName);
      if(!isCelebrity)
        startSynchronize()
    }
  }, [])

  const youtubeReady = (result) => {
    const playerInfo = {
      id: 'stream',
      videoUrl: video,
      playerType: PlayerType.youtube
    }
    result.target.playVideo();
    setYoutubeToSdk(playerInfo, result.target);
    if(!isCelebrity)
      startSynchronize()

    attachEventListener((event) => {
      if(event.data.message.command ==='play'){
        try{
          result.target.playVideo();
        }catch(error){
          console.log(error)
        }

      }
      if(event.data.message.command ==='pause'){
        try{
          result.target.pauseVideo();
        }catch(error){
          console.log(error)
        }
      }
    })
  }

  const sendPlayEvent = () => {
    sendEvent({ command: 'play'})
  }
  const sendPauseEvent = () => {
    sendEvent({ command: 'pause' })
  }

  const opts = {

    playerVars: {
      height: '500',
      width: '640',
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
      <div className='player-container'>
        <YouTube id='stream' videoId={video}
                 opts={opts}
                 onPlay={() => sendPlayEvent()}
                 onPause={() => sendPauseEvent()}
                 onReady={(Instance) =>youtubeReady(Instance) } />
      </div>
  );
};
export default Player;
