import React from 'react'
import { useDispatch } from 'react-redux';
import './Buttons.css'

import { setJoinModalType } from '../../../../store/reducers/SessionReducer'

function Buttons() {
  const dispatch = useDispatch()

  const handleSubmit = (type) => {
    dispatch(setJoinModalType(type))
  }

  return (
    <>
      <button
        className='sd-button'
        onClick={() => handleSubmit('USER_DATA_ENTRY')}
      >
        Start your room
      </button>
    </>
  )
}

export default Buttons
