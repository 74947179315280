import React from 'react'
import {useParams, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setStreamLink} from '../../store/reducers/ParticipantReducer';

const Stream = () => {
    const {id} = useParams();
    const dispatch = useDispatch();

    function validURL(str) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }
    try {
        const streamLink = atob(id);
        if(validURL(streamLink)) {
            dispatch(setStreamLink(streamLink));
            return <Redirect to={'/'} />
        } else {
            return <Redirect to={'/'} />
        }
    } catch (e) {
        return <Redirect to={'/'} />
    }

}

export default Stream