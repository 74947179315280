import React, {useEffect, useState} from 'react'
import {Route, Switch, Redirect} from 'react-router-dom';
import './App.css';
import './anybody.css';

import Session from './components/Session/Session';
import Stream from './components/Stream/Stream';
import fifaeLogo from "./assets/fifa-e-logo.svg";

function App() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            setIsMobile(true)
        }

    }, []);

    if(isMobile) {
        return (
            <div className='do-not-support'>
                <div className={'not-supported-logo'}>
                    <img src={fifaeLogo} alt={'FIFAe'}/>
                </div>
                <div className={'not-supported-text'}>
                    <p>We currently do not support the watch together experience on mobile. Please visit this page from a laptop or desktop computer to watch the official live stream together with your friends.</p>
                </div>

                <div className='not-supported-back-space'>
                    <a href={'https://www.fifa.gg'} title={'FIFA.gg'} className={'not-supported-back-button'}
                       target={'_blank'}>Back to FIFA.GG</a>
                </div>
            </div>
        )
    }

    return (
        <>
            <Switch>
                <Route path='/' exact component={() => <Redirect to='/p'/>}/>

                <Route path='/p/:roomId?' component={Session} exact/>

                <Route path='/c/:roomId?' component={Session} exact/>
                <Route path='/s/:id' component={Stream} exact/>
            </Switch>
        </>
    );
}

export default App;
