import React, { useState, useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import './JoinModal.css'

import { setJoinModalType } from '../../store/reducers/SessionReducer'

import Buttons from './components/Buttons/Buttons'
import ScheduleForm from './components/ScheduleForm/ScheduleForm'
import CopyModal from './components/CopyModal/CopyModal'
import UserDataEntry from './components/UserDataEntry/UserDataEntry'

function JoinModal(props) {
  const { setModalType, } = props

  const dispatch = useDispatch()


  const joinModalType = useSelector(state => state.session.joinModalType);
  const { token } = useSelector(state => state.participant);

  const [loadingState, setLoadingState] = useState(true)

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('/c')) {
      dispatch(setJoinModalType('USER_DATA_ENTRY'))
    }
    setLoadingState(false)
  }, [dispatch, pathname, setModalType])

  const handleClose = () => {
    if (token) {
      setModalType(null)

      dispatch(setJoinModalType(null))
    } else {
      switch (joinModalType) {
        case 'USER_DATA_ENTRY':
          return dispatch(setJoinModalType('BUTTONS'))
        case 'SCHEDULE_MODAL':
          return dispatch(setJoinModalType('BUTTONS'))
        case 'COPY_MODAL':
          return dispatch(setJoinModalType('BUTTONS'))
        default:
          return dispatch(setJoinModalType('BUTTONS'))
      }
    }
  }

  const renderModal = () => {
    switch (joinModalType) {
      case 'BUTTONS': 
        return <Buttons />
      case 'USER_DATA_ENTRY':
        return <UserDataEntry />
      case 'SCHEDULE_MODAL':
        return <ScheduleForm />
      case 'COPY_MODAL':
        return <CopyModal />
      default:
        return <Buttons />
    }
  }

  if (!joinModalType) {
    return null
  }

  if (loadingState) {
    return null
  }

  return (
    <div className='sd-wrapper'>
      <div className='sd-container'>
        <h1 className={'sd-header'}>Watch Together</h1>
        <p className={'sd-text'}>Watch the official stream and invite your friends to watch with you.</p>
        {renderModal()}

        {joinModalType !== 'BUTTONS' && (
          <div className='sd-close' onClick={handleClose} />
        )}
      </div>
    </div>
  )
}

export default JoinModal;
